<script>

export default {
  components: {  

  },
  props: {
    addTagText: {
      type: String
    },
    tags: {
      type: Array,
      required: false,
      default: ()=> []
    },
    value: {
      type: Array,
      required: false,
      default: ()=> []
    },
    editable:{
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      tagName: '',
      isAdding: false
    };
  },
  computed: {
    filterItems(){
      return this.tagName ? this.tags.filter(x => x.includes(this.tagName)) : this.tags;
    }
  },
  methods:{
    add(){
      this.tagName = null;
      this.isAdding = true;
      this.$nextTick(()=> this.$refs.input.focus());
    },
    addTag(tagName){
     
      this.$emit("update", [...new Set([...this.value, tagName])]);
      this.tagName = null;
      this.isAdding = false;
    },
    end() {
     
      if(this.tagName) {
        this.$emit("update", [...new Set([...this.value, this.tagName])]);
      }
      this.tagName = null;
      this.isAdding = false;
    },
    remove(tag){
      this.$emit("update", [...new Set(this.value.filter(x => x !== tag))]);
    }
  }
};
</script>

<template>
  <span>
   
    <span v-if="!editable"> 
      <span class="badge badge-soft-dark me-2 mb-1" 
        v-for="(t, $index) in value" :key="$index"
      >
        <font-awesome-icon icon="fa-solid fa-tag" />
        {{t}}
      </span>
    </span>
    <span v-if="editable">
      <span class="me-2 form-control me-2 mb-1" 
        style="display:inline-block; width:auto" 
        
        v-for="(t, $index) in value" :key="$index"
      >
        <font-awesome-icon icon="fa-solid fa-tag" />
      
        {{t}}
        <small class="ms-2"  v-if="editable">
          <a href="javascript:void(0)" @click="remove(t)">&#10006;</a>
        </small>

      </span>
    </span>
    
    <a href="javascript:void(0)" 
      class="btn btn-outline-light waves-effect"
      v-if="!isAdding && editable"
      @click="add"
    >
      <font-awesome-icon icon="fa-solid fa-plus" class="me-1" />
      <span v-if="addTagText">{{addTagText}}</span>
      <span v-if="!addTagText"> {{$t('common.addTag')}}</span>
     
    </a>
    <div class="form-control " style="display:inline-block; min-width:150px;width:150px;position:relative; padding:0; z-index:10"
      v-click-outside="end"  
      v-if="isAdding"
     >
      <input 
        class="form-control "
        style="border:0;"
        v-model="tagName" 
        ref="input"
       
        v-on:keyup.enter="end"
      />
      <div style="position: absolute; top:28px; left:-1px; min-width:150px; min-height:100px; overflow-y:auto; " v-if="filterItems.length > 0">
        <div class="list-group"  >

          <a href="javascript:void(0)" 
            class="list-group-item text-nowrap"
            style="max-width:250px;text-overflow: ellipsis;overflow: hidden; " 
            v-for="(tag, $index) in filterItems" 
            :key="$index"
            @click.prevent="addTag(tag)"
            >
            {{tag}}
          </a>
        </div>
      </div>
    </div>
   
  </span>
</template>
