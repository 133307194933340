<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment-timezone';
import { required, email } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import { Modal } from 'bootstrap'
import { parseErrors} from "@/helpers"
import PhoneNumber from "@/components/phone-number"
import { popModal, pushModal } from "@/modals";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
   PhoneNumber
  },
  props: {
    id:{
       type: String,
       required: true
    }
  },
  data() {
    return {
      title: null,
      errors: [],
      item: {
        name: '',
        email: '',
        phoneNumber: '',
        description: '',
        isAdmin: false,
        password: null,
        lockoutEnd: null,
        groups: []
      }
    };
  },
  validations() {
    const rules = {
      item: {
        email: { required, email },
        name: { required }
      }
    };

    if(this.isNew) {
      rules.item.password = { required };
    }
    return rules;
  },
  created(){
    this.title =this.isNew ? this.$t('common.newRecord') : this.$t('common.editRecord');
    this.init();
  },
  mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'static', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });
  },
  computed :{
    isNew () {
      return this.id.toLowerCase().trim() === 'new';
    },
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('users', {
      loading: 'loading',
      deleting: 'deleting',
      processing: 'processing'
    }),
    ...mapGetters('userGroups', {
      groups: 'items'
    }),
  },
  methods: {
    ...mapActions('users', {
      loadUser: 'loadUser',
      createUser: 'createUser',
      updateUser: 'updateUser',
    }),
    async close(res) {
       var modal = Modal.getInstance(this.$refs.modal);
       modal.hide();
      popModal();
       this.$emit("close", res);
    },
    async init(){
      if(!this.isNew) {
        const dto = await this.loadUser( { id: this.id });
        const {
          name,
          email,
          phoneNumber,
          description,
          isAdmin,
          lockoutEnd,
          deletedAt,
          groups
        } = dto;
        this.item = { name, email, phoneNumber, description, isAdmin, deletedAt, groups: groups.map(x => x.id), lockoutEnd: lockoutEnd ? moment(lockoutEnd).format("YYYY-MM-DD") : null, password: null};
      }
    },
    selectGroup(group, value) {
      if(value)
        this.item.groups = [...this.item.groups, group.id];
      else
        this.item.groups = [...this.item.groups.filter(x => x !== group.id)];
    },
    async submit () {
      const isValid = await this.v$.$validate();
      if(isValid) {
        this.errors = [];
        const dto = {...this.item, lockoutEnd: this.item.lockoutEnd || null };
        let req = this.isNew ? this.createUser({ dto }) : this.updateUser({ id: this.id, dto: dto});
        req.then(() => {
           this.$toast.open({message: this.$t('common.savedText')});
          this.close(true);
        })
        .catch(error => {
          this.errors = parseErrors(error);
        });
      }
    }
  }
};
</script>

<template>
  <div
    class="modal"
    ref="modal"
  >
    <div class="modal-dialog  modal-dialog-scrollable modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{title}}<i class="fa fa-spin fa-spinner ms-2" v-if="loading"/>
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="alert mt-2 mb-2 alert-dismissible alert-danger" v-if="errors.length > 0">
              <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
              <ul class="list-unstyled">
                <li v-for="e in errors" :key="e">{{e}}</li>
              </ul>
            </div>

            <div class="mb-4">
              <label for="name-input" class="col-form-label">{{$t('users.nameField')}}</label>
              <div >
                <input 
                  type="text" 
                  class="form-control" 
                  id="name-input" 
                  v-model="item.name"
                  :class="{ 'is-invalid': v$.item.name.$error }"
                />
                <div class="invalid-feedback">
                 {{ $t("common.pleaseEnter") }} {{ $t("users.nameField") }}
                </div> 
              </div>
            </div>
            <div class="mb-4">
              <label for="email-input" class="col-form-label">{{$t('users.emailField')}}</label>
              <div class="">
                <input 
                  autocomplete="nope"  autofill="nope"
                  type="email" 
                  class="form-control" 
                  id="email-input" 
                  v-model="item.email"
                  :class="{ 'is-invalid': v$.item.email.$error }"
                />
                <div class="invalid-feedback">
                   {{ $t("common.pleaseEnter") }} {{ $t("users.emailField") }}
                </div> 
              </div>
            </div>
            <div class="">
              <label for="phone-input" class="col-form-label">{{$t('users.phoneField')}}</label>
              <div class="">
                <phone-number :value="item.phoneNumber" @changed="(v) => item.phoneNumber = v" placeholder="Enter Phone Number" >

                </phone-number>
              </div>
            </div>
            <div class=" mb-4">
              <label for="isadmin-input" class="col-form-label">{{$t('users.roleField')}}</label>
              <div class="">
                <div class="form-check form-switch form-switch-md mb-3">
                  <input class="form-check-input" type="checkbox" id="isadmin-input" v-model="item.isAdmin"/>
                  <label class="form-check-label" for="isadmin-input">{{$t('users.admiRole')}}</label>
                </div>
              </div>
            </div>
             <div class="mb-4">
              <label for="email-input" class="col-form-label">{{ $t("users.groupsField") }}</label>
              <div>
                <div v-for="group in groups.filter(x => !x.everyone)" :key="group.id"> 
                  <input type="checkbox" class="px-2 me-2" :checked="item.groups.indexOf(group.id) >= 0" @change="($event) => selectGroup(group, $event.target.checked)">{{group.name}}
                </div>
              </div>
            </div>
            <div class=" mb-4">
              <label for="lockoutEnd-input" class=" col-form-label">{{$t('users.lockoutEndField')}}</label>
              <div class="">
                <input 
                  type="date" 
                  class="form-control" 
                  placeholder="Enter Phone Number" 
                  id="lockoutEnd-input" 
                  v-model="item.lockoutEnd"
                />
              </div>
            </div>
            <div class=" mb-4" v-if="isNew">
              <label for="password-input" class="col-form-label">{{$t('users.passwordField')}}</label>
              <div class="">
                <input 
                  autofill="nope"  autocomplete="new-password"
                  type="password" 
                  class="form-control" 
                 
                  id="password-input" 
                  v-model="item.password"
                  :class="{ 'is-invalid': v$.item.password.$error }"
                />
                <div class="invalid-feedback">
                  {{ $t("common.pleaseEnter") }} {{ $t("users.passwordField") }}
                </div> 
              </div>
            </div>
            <div class=" mb-4" v-if="!isNew">
              <label for="password-input" class="col-form-label">{{$t('users.passwordField')}}</label>
              <div class="">
                <input 
                autofill="nope"  autocomplete="new-password"
                  type="password" 
                  class="form-control" 
                 
                  id="password-input" 
                  v-model="item.password"
                />
              </div>
            </div>
            <div class=" mb-4">
              <label for="description-input" class="col-form-label">{{$t('users.descriptionField')}}</label>
              <div class="">
                <textarea 
                  rows="5"
                  class="form-control" 
                  placeholder="Enter Description" 
                  id="description-input" 
                  v-model="item.description"
                ></textarea>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            @click="close"
          >
             {{$t('common.closeButtonTitle')}}
          </button>
          <button type="button" class="btn btn-primary"
            :disabled="processing || loading" 
            @click="submit"
          >
           <i class="fa fa-spin fa-spinner me-2" v-if="processing"/>
           <i class="fa fa-save me-2" v-if="!processing"/>
            {{$t('common.saveButtonTitle')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
