<script>

import { mapGetters } from 'vuex';
import EditForm from "@/views/pages/users/edit-form"

export default {
  components: {  
    EditForm  
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: ()=> []
    }
  },
  data() {
    return {
      userName: '',
      isAdding: false,
      editItemId: null
    };
  },
  computed: {
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('users', {
      loading: 'loading',
      users: 'itemsOnlyAlive'
    }),
    me() {
      return this.users.find(x => x.id == this.currentUser.id);
    },
    filterItems(){
      return this.userName ? this.users.filter(x => x.id != this.me.id && (x.name.includes(this.userName) || x.email.includes(this.userName))) : this.users.filter(x => x.id != this.me.id);
    }
  },
  methods:{
    add(){
      this.userName = null;
      this.isAdding = true;
      this.$nextTick(()=> this.$refs.input.focus());
    },
    addUser(user){
      const ids = this.value.map(x => x.id);
      const set = [...new Set([...ids, user.id])];
      const users = set.map(x => this.users.find(a => a.id === x)).filter(x => x);
      this.$emit("update", users);
      this.end();
    },
    end() {
      this.userName = null;
      this.isAdding = false;
    },
    remove(user){
      const ids = this.value.filter(x => x.id !== user.id).map(x => x.id);
      const set = [...new Set([...ids])];
      const users = set.map(x => this.users.find(a => a.id === x)).filter(x => x);
      this.$emit("update", users);
    }
  }
};
</script>

<template>
  <span>
    <edit-form v-if="editItemId" @close="editItemId = null" :id="editItemId">
    </edit-form>
    <span>
      <span class="me-2 form-control me-2 mb-1 bg-light" 
        style="display:inline-block; width:auto" 
        
        v-for="(t, $index) in value" :key="$index"
      >
        <span v-if="t.id === currentUser.id">
          @Me
        </span>
        <span v-if="t.id !== currentUser.id">
          {{t.name}}
        </span>
        <small class="ms-2">
          <a href="javascript:void(0)" @click="remove(t)">&#10006;</a>
        </small>

      </span>
    </span>
    
    <a href="javascript:void(0)" 
      class="btn btn-outline-light waves-effect "
      v-if="!isAdding "
      @click="add"
    >
      <i class="fas fa-plus me-1"></i> Add
    </a>
    <div class="form-control " style="display:inline-block; min-width:150px;width:150px;position:relative; padding:0; z-index:10"
      v-click-outside="end"  
      v-if="isAdding"
     >
      <input 
        class="form-control"
        style="border:0;"
        v-model="userName" 
        ref="input"
      />
      <div style="position: absolute;  left:-1px; min-width:150px; min-height:100px; overflow-y:auto; ">
        <div class="list-group"  >
          <a 
            href="javascript:void(0)"
            class="list-group-item text-nowrap"
            style="max-width:250px;text-overflow: ellipsis;overflow: hidden;" 
            v-if="!value.find(x => x.id === me.id)"
            @click.prevent="addUser(me)"
            >
            @Me
          </a>
          <template
            v-for="(user, $index) in filterItems" 
            :key="$index"
          >
            <a href="javascript:void(0)" 
              class="list-group-item text-nowrap"
              style="max-width:250px;text-overflow: ellipsis;overflow: hidden; " 
              v-if="!value.find(x => x.id === user.id)"
              @click.prevent="addUser(user)"
              >
              {{user.name}}
              
            </a>
            
          </template>
        </div>
      </div>
    </div>
   
  </span>
</template>
